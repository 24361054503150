export const initialState = {
    mu: 80,
    muNull: 80,
    muTheta: 100,
    muHat: "",
    SS: "",
    sigma2: 100,
    sigma2Max: 650,
    sigma2Theta: 225,
    sigma2MleNull: "",
    sigma2Hat: "",
    n: 10,
    test: "LRT",
    sample: [1, 2],
    sampleZ: [],
    sliderMax: 150,
    sliderStep: 0.1,
    drawGradientPath: [],
    algoDelay: null,
    algoDelaySetting: null,
    animating: false,
    count: 0,
    algo: "gradientAscent"
  };